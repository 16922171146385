// @ts-nocheck
import "babel-polyfill";
import React from 'react'
import ReactDOM from 'react-dom'
import {Naskor, NaskorSearchResult} from "./components/naskor";
import ErrorBoundary from "./components/errorBoundary";

class SearchResult extends React.Component<{
    result: NaskorSearchResult
}> {
    render() {
        if(!this.props.result || !this.props.result.products) {
            return '';
        }

        const productsRows = this.props.result.products.map((product) => {
            return (
                <li key={product.id}>
                    <div>
                        <a href={Naskor.Routing.generate('product-size-detail', {slug: product.id})}>
                            <img src={product.image_uri} alt={product.name} className="img-responsive" />
                            {product.image_overlay_uri ? <img src={product.image_overlay_uri} alt={product.name} className="img-responsive img-overlay"/> : ''}
                        </a>
                    </div>
                    <div>
                        <a href={Naskor.Routing.generate('product-size-detail', {slug: product.id})}>
                            <strong>{product.brand.name}</strong><br/><span className="visible-xs-inline">&nbsp;</span>
                            {product.name}
                        </a>
                    </div>
                </li>
            );
        });

        const brandRows = this.props.result.brands.map((brand) => {
            return (
                <li key={brand.id}>
                    <div>
                        <a href={Naskor.Routing.generate('brand-product-list', {slug: brand.id})}>
                            {brand.name}
                        </a>
                    </div>
                </li>
            );
        });

        return (

            <div className="search-results">
                {/*
                <div className="search-results__suggested">
                    <h2>Suggested</h2>
                    <ul>
                        <li>
                            <a href="#" title="">
                                item
                            </a>
                        </li>
                    </ul>
                </div>
                */}
                <h2>Brands</h2>
                <ul>
                    {brandRows && brandRows.length ? brandRows : <li className="no-search-results">No results for your search</li> }
                </ul>
                <h2>Products</h2>
                <ul className="search-results--2col">
                    {productsRows && productsRows.length ? productsRows : <li className="no-search-results">No results for your search</li> }
                </ul>
            </div>
        )
    }
}

class SearchInput extends React.Component<{
},{
    query: string;
    result: NaskorSearchResult;
    active: boolean;
}> {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            active: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        const oldValue = prevState.active;
        const newValue = this.state.active;
        if(oldValue !== newValue) {
            const shouldOpen = !oldValue && newValue;
            const shouldClose = oldValue && !newValue;
            if(shouldOpen) {
                $('#SearchInput').addClass('search–expanded');
            }else if(shouldClose) {
                $('#SearchInput').removeClass('search–expanded');
            }
        }
    }


    onFocus = () => {
        this.setState({
            active: true
        }, () => {
            this.changeResult();
        })
    };

    handleClickOutside = (event) => {
        if (this.element && !this.element.contains(event.target)) {
            this.setState({
                active: false,
            }, () => {
                this.changeResult();
            })
        }

    };

    changeResult() {
        if (this.state.active && this.state.query && this.state.query.length > 1) {
            // if (this.state.query.length % 2 === 0) {
                this.getResults();
            // }
        } else {
            this.setState({
                result: {}
            })
        }
    };

    handleInputChange = () => {
		this.setState({
			query: this.search.value
		}, () => {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.changeResult();
			}, 500);
		})
    };

    getResults = () => {
        const url = Naskor.Routing.generate('search', {
            query: this.state.query
        });
        return Naskor.fetchJson(url)
            .then(data => {
                if(this.state.active) {
                    this.setState({
                        result: data
                    });
                }
            });
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.showSearchResultList();
        }
    };

    showSearchResultList = () => {
        window.location.href = Naskor.Routing.generate('search-result-product-list', {
            query: this.state.query
        });
    };

    render() {
        return (
            <div ref={(element) => { this.element = element; }}>
                <input
                    type="search"
                    placeholder="Search"
                    ref={input => this.search = input}
                    onFocus={this.onFocus}
                    onChange={this.handleInputChange}
                    onKeyPress={this.handleKeyPress}
                    value={this.state.query}
                />
                    <button onClick={this.showSearchResultList} className="btn btn-default" type="submit" id="submitSearch">
                        <i className="glyphicon glyphicon-search"></i>
                    </button>
                <SearchResult result={this.state.result}/>
            </div>
        )
    }
}

ReactDOM.render(
    <ErrorBoundary>
        <SearchInput />
    </ErrorBoundary>,
    document.getElementById('SearchInput')
);



