// @ts-nocheck
import "babel-polyfill";
import React from "react";

export default class ErrorBoundary extends React.Component <{
    children: React.Component[];
}, {
    hasError: boolean;
} > {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, errorInfo) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        Raven.captureException(error, {extra: errorInfo});
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // return <h1>Something went wrong.</h1>;

            return (
                <div
                    className="snap"
                    onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>
                    <p>We're sorry — something's gone wrong.</p>
                    <p>Our team has been notified, but click <u>here</u> to fill out a report.</p>
                </div>
            );
        }
        return this.props.children;
    }
}